import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { getErrorMessage } from '~graphql/error';
import {
  CreateOrganizationMutation,
  useCreateOrganizationMutation,
} from './__generated__/CreateOrganizationModal.graphql';

interface Props {
  isOpen: React.ComponentProps<typeof Modal>['isOpen'];
  onClose: React.ComponentProps<typeof Modal>['onClose'];
  onSuccess: (data: CreateOrganizationMutation['organizationCreate']) => void;
}

const HANDLE_REGEX = /[^a-z0-9-_]/;

const ErrorMessages: { [key: string]: string } = {
  ORGANIZATION_NAME_DUPLICATE: 'Organization with the same name already exists',
  ORGANIZATION_HANDLE_DUPLICATE: 'Organization with the same handle already exists',
  USER_COULD_NOT_BE_CREATED: 'This user already has an organization',
};

export const nameSchema = z.string().min(3);

export const handleSchema = z
  .string()
  .min(3)
  .refine((v) => !HANDLE_REGEX.test(v), 'Handle must be lowercase alphanumeric');

const formSchema = z.object({
  name: nameSchema,
  handle: handleSchema,
  ownerEmail: z.string().email(),
  ownerGivenName: z.string().min(2),
  ownerFamilyName: z.string().min(2),
});

type FormValues = z.TypeOf<typeof formSchema>;

export function CreateOrganizationModal({ isOpen, onClose, onSuccess }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <CreateOrganizationModalContent onClose={onClose} onSuccess={onSuccess} />
      </ModalContent>
    </Modal>
  );
}

export function CreateOrganizationModalContent({
  onClose,
  onSuccess,
}: {
  onClose: Props['onClose'];
  onSuccess: Props['onSuccess'];
}) {
  const [createOrganization] = useCreateOrganizationMutation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const toast = useToast({ position: 'bottom-right' });

  const onSubmit = useCallback(
    async ({ name, handle, ownerEmail, ownerGivenName, ownerFamilyName }: FormValues) => {
      try {
        const { data } = await createOrganization({
          variables: {
            input: {
              name,
              handle,
              ownerEmail,
              ownerGivenName,
              ownerFamilyName,
            },
          },
        });

        if (!data || !data.organizationCreate) {
          throw new Error('Create Organization failed');
        }

        onSuccess(data.organizationCreate);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        if (errorMessage) {
          setError('name', {
            type: 'api',
            message: ErrorMessages[errorMessage],
          });
        } else {
          toast({
            title: 'Could not create organization',
            description: 'Unknown internal server error',
            status: 'error',
          });
          console.log('Unhandled error: ', err);
        }
      }
    },
    [createOrganization, onSuccess, setError, toast],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>Add New Organization</ModalHeader>
      <ModalBody>
        <VStack w="full" align="left">
          <Box>
            <FormControl isInvalid={Boolean(errors.name)}>
              <FormLabel>Name:</FormLabel>
              <Input tabIndex={1} placeholder="Organization name" {...register('name')} />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box>
            <FormControl isInvalid={Boolean(errors.handle)}>
              <FormLabel>Handle:</FormLabel>
              <Input tabIndex={2} placeholder="Organization handle" {...register('handle')} />
              <FormErrorMessage>{errors.handle?.message}</FormErrorMessage>
              <FormHelperText>Handle must be URL friendly</FormHelperText>
            </FormControl>
          </Box>
          <Box>
            <FormControl isInvalid={Boolean(errors.ownerEmail)}>
              <FormLabel>Owner Email:</FormLabel>
              <Input tabIndex={3} placeholder="john.doe@example.com" {...register('ownerEmail')} />
              <FormErrorMessage>{errors.ownerEmail?.message}</FormErrorMessage>
              <FormHelperText>Email of the first organization user</FormHelperText>
            </FormControl>
          </Box>
          <Box>
            <FormControl isInvalid={Boolean(errors.ownerGivenName)}>
              <FormLabel>Owner first name:</FormLabel>
              <Input tabIndex={4} placeholder="Owner first name" {...register('ownerGivenName')} />
              <FormErrorMessage>{errors.ownerGivenName?.message}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box>
            <FormControl isInvalid={Boolean(errors.ownerFamilyName)}>
              <FormLabel>Owner last name:</FormLabel>
              <Input tabIndex={5} placeholder="Owner last name" {...register('ownerFamilyName')} />
              <FormErrorMessage>{errors.ownerFamilyName?.message}</FormErrorMessage>
            </FormControl>
          </Box>
        </VStack>
        <ModalFooter>
          <Box>
            <Button variant="ghost" isDisabled={isSubmitting} onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              type="submit"
              variant="solid"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Create
            </Button>
          </Box>
        </ModalFooter>
      </ModalBody>
    </form>
  );
}

CreateOrganizationModal.graphql = {
  mutations: {
    CreateOrganization: gql`
      mutation CreateOrganization($input: OrganizationCreateInput!) {
        organizationCreate(input: $input) {
          id
          ...OrganizationRow
        }
      }
    `,
  },
};
