/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type PlatformFirmwareReleaseFragment = { __typename?: 'Platform', id: string, name: string, version?: string | null, type: Types.PlatformType };

export type RequestFirmwareUploadMutationVariables = Types.Exact<{
  input: Types.FirmwareUploadRequestInput;
}>;


export type RequestFirmwareUploadMutation = { __typename?: 'Mutation', firmwareUploadRequest: { __typename?: 'FirmwareUploadRequestPayload', uploadUrl: string, firmwareUrl: string } };

export type ReleaseFirmwareMutationVariables = Types.Exact<{
  input: Types.FirmwareReleaseInput;
}>;


export type ReleaseFirmwareMutation = { __typename?: 'Mutation', firmwareRelease: { __typename?: 'FirmwareReleasePayload', platform: { __typename?: 'Platform', id: string, type: Types.PlatformType, latestFirmwareRelease?: { __typename?: 'LatestFirmware', android: { __typename?: 'AndroidFirmware', version: any } } | null } } };

export const PlatformFirmwareReleaseFragmentDoc = gql`
    fragment PlatformFirmwareRelease on Platform {
  id
  name
  version
  type
}
    `;
export const RequestFirmwareUploadDocument = gql`
    mutation RequestFirmwareUpload($input: FirmwareUploadRequestInput!) {
  firmwareUploadRequest(input: $input) {
    uploadUrl
    firmwareUrl
  }
}
    `;
export type RequestFirmwareUploadMutationFn = ApolloReactCommon.MutationFunction<RequestFirmwareUploadMutation, RequestFirmwareUploadMutationVariables>;

/**
 * __useRequestFirmwareUploadMutation__
 *
 * To run a mutation, you first call `useRequestFirmwareUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestFirmwareUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestFirmwareUploadMutation, { data, loading, error }] = useRequestFirmwareUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestFirmwareUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestFirmwareUploadMutation, RequestFirmwareUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RequestFirmwareUploadMutation, RequestFirmwareUploadMutationVariables>(RequestFirmwareUploadDocument, options);
      }
export type RequestFirmwareUploadMutationHookResult = ReturnType<typeof useRequestFirmwareUploadMutation>;
export type RequestFirmwareUploadMutationResult = ApolloReactCommon.MutationResult<RequestFirmwareUploadMutation>;
export type RequestFirmwareUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestFirmwareUploadMutation, RequestFirmwareUploadMutationVariables>;
export const ReleaseFirmwareDocument = gql`
    mutation ReleaseFirmware($input: FirmwareReleaseInput!) {
  firmwareRelease(input: $input) {
    platform {
      id
      type
      latestFirmwareRelease {
        android {
          version
        }
      }
    }
  }
}
    `;
export type ReleaseFirmwareMutationFn = ApolloReactCommon.MutationFunction<ReleaseFirmwareMutation, ReleaseFirmwareMutationVariables>;

/**
 * __useReleaseFirmwareMutation__
 *
 * To run a mutation, you first call `useReleaseFirmwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseFirmwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseFirmwareMutation, { data, loading, error }] = useReleaseFirmwareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReleaseFirmwareMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReleaseFirmwareMutation, ReleaseFirmwareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ReleaseFirmwareMutation, ReleaseFirmwareMutationVariables>(ReleaseFirmwareDocument, options);
      }
export type ReleaseFirmwareMutationHookResult = ReturnType<typeof useReleaseFirmwareMutation>;
export type ReleaseFirmwareMutationResult = ApolloReactCommon.MutationResult<ReleaseFirmwareMutation>;
export type ReleaseFirmwareMutationOptions = ApolloReactCommon.BaseMutationOptions<ReleaseFirmwareMutation, ReleaseFirmwareMutationVariables>;