import { gql } from '@apollo/client';
import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Input,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { CreateOrganizationModal } from '~components/organizations/CreateOrganizationModal';
import { DeleteOrganizationModal } from '~components/organizations/DeleteOrganizationModal';
import { EditOrganizationModal } from '~components/organizations/EditOrganizationModal';
import { OrganizationsTable } from '~components/organizations/OrganizationsTable';
import { useOrganizationsTable } from '~components/organizations/useOrganizationsTable';
import AppLayout from '~components/ui/AppLayout';
import Breadcrumb from '~components/ui/Breadcrumb';
import Oops from '~components/ui/Oops';
import { useQueryParams } from '../UseQueryParams';
import { useGetAllOrganizationsQuery } from './__generated__/Organizations.graphql';

enum Action {
  Edit = 'edit',
  Delete = 'delete',
}

function OrganizationsPage() {
  const toast = useToast();
  const { loading, error, data, refetch } = useGetAllOrganizationsQuery();
  const { isOpen: isCreateOpen, onOpen: createOnOpen, onClose: createOnClose } = useDisclosure();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<null | string>(null);
  const [selectedAction, setSelectedAction] = useState<null | Action>(null);
  const queryParams = useQueryParams();
  const [search, setSearch] = React.useState(queryParams.get('search') || '');

  const debouncedSearch = useRef(
    debounce(async (newSearch: string) => {
      history.replaceState(
        {},
        window.document.title,
        newSearch ? `?search=${newSearch}` : window.location.pathname,
      );
      setSearch(newSearch);
    }, 100),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  const handleCreateOnSuccess = useCallback(
    ({ name }: { name: string }) => {
      refetch();
      createOnClose();
      toast({
        title: 'Organization created',
        description: `A new organization organization: ${name} has been created`,
        status: 'success',
      });
    },
    [createOnClose, refetch, toast],
  );

  const editOnClose = useCallback(() => {
    setSelectedOrganizationId(null);
    setSelectedAction(null);
  }, [setSelectedOrganizationId, setSelectedAction]);

  const editOnOpen = useCallback(
    (id: string) => {
      setSelectedOrganizationId(id);
      setSelectedAction(Action.Edit);
    },
    [setSelectedOrganizationId, setSelectedAction],
  );

  const deleteOnOpen = useCallback(
    (id: string) => {
      setSelectedOrganizationId(id);
      setSelectedAction(Action.Delete);
    },
    [setSelectedOrganizationId, setSelectedAction],
  );

  const handleEditOnSuccess = useCallback(
    ({ name }: { name: string }) => {
      refetch();
      editOnClose();
      toast({
        title: 'Organization updated',
        description: `organization organization: ${name} has been updated`,
        status: 'success',
      });
    },
    [editOnClose, refetch, toast],
  );

  const handleDeleteOnSuccess = useCallback(
    ({ name }: { name: string }) => {
      refetch();
      editOnClose();
      toast({
        title: 'Organization deleted',
        description: `Organization ${name} has been deleted`,
        status: 'success',
      });
    },
    [editOnClose, refetch, toast],
  );

  const selectedOrganization = useMemo(
    () =>
      (selectedOrganizationId &&
        data?.organizations.find((o) => o.id === selectedOrganizationId)) ||
      null,
    [data, selectedOrganizationId],
  );

  const table = useOrganizationsTable(
    useMemo(() => {
      const searchMatch = (...args: string[]) =>
        args.some((str) => str.toLowerCase().includes(search.toLowerCase()));

      return {
        data:
          (search
            ? data?.organizations.filter((org) =>
                searchMatch(org.name, org.handle, org.owner?.email ?? ''),
              )
            : data?.organizations) || [],
        onOrganizationEdit: editOnOpen,
        onOrganizationDelete: deleteOnOpen,
      };
    }, [data?.organizations, editOnOpen, deleteOnOpen, search]),
  );

  return (
    <>
      <Helmet>
        <title>Organizations</title>
      </Helmet>

      <>
        <AppLayout.FixedContent>
          <Box px="16" py="4" bg="gray.200">
            <Breadcrumb>
              <Breadcrumb.Item>TP Vision</Breadcrumb.Item>
              <Breadcrumb.ItemLink to={`/organizations`}>Organizations</Breadcrumb.ItemLink>
            </Breadcrumb>
          </Box>
        </AppLayout.FixedContent>

        <AppLayout.ScrollableContent ml="8">
          <HStack alignItems="center" mt="4" spacing="5">
            <Heading>Organizations</Heading>
            <Box>
              <Button
                leftIcon={<AddIcon />}
                size="xs"
                variant="outline"
                onClick={() => createOnOpen()}
              >
                Add New Organization
              </Button>
            </Box>
          </HStack>

          <Box marginY="8">
            <Input
              id="search"
              type="text"
              onChange={handleSearch}
              placeholder="Type to search..."
              defaultValue={search}
            />
          </Box>

          <Box marginTop="8">
            {loading && (
              <Center flex={1}>
                <Spinner size="lg" />
              </Center>
            )}
            {error && <Oops error={error} />}
            {data && <OrganizationsTable table={table} />}
          </Box>
        </AppLayout.ScrollableContent>
        {selectedOrganization && selectedAction === Action.Edit && (
          <EditOrganizationModal
            isOpen={Boolean(selectedOrganizationId)}
            onClose={editOnClose}
            onSuccess={handleEditOnSuccess}
            organization={selectedOrganization}
          />
        )}
        {selectedOrganization && selectedAction === Action.Delete && (
          <DeleteOrganizationModal
            isOpen={Boolean(selectedOrganizationId)}
            onClose={editOnClose}
            onSuccess={handleDeleteOnSuccess}
            organization={selectedOrganization}
          />
        )}
        <CreateOrganizationModal
          isOpen={isCreateOpen}
          onClose={createOnClose}
          onSuccess={handleCreateOnSuccess}
        />
      </>
    </>
  );
}

OrganizationsPage.graphql = {
  queries: {
    GetAllOrganizations: gql`
      query GetAllOrganizations {
        organizations {
          id
          ...OrganizationRow
        }
      }
    `,
  },
};

export default OrganizationsPage;
