import { WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  chakra,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import gql from 'graphql-tag';
import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import Dropzone from '~components/ui/Dropzone';
import Stepper from '~components/ui/Stepper';
import { getErrorMessage } from '~graphql/error';
import { encodeMD5Checksum, MD5ChecksumEncodingError } from '~utils/encoding';
import {
  getAcceptedFileTypesForPlatform,
  getPlatformFormSchema,
  getPlatformSpecificFirmwarePlaceholder,
} from '~utils/platformUtils';
import { useMockS3Upload } from '~utils/useMockS3Upload';
import { useS3Upload } from '~utils/useS3Upload';
import {
  PlatformFirmwareReleaseFragment,
  useReleaseFirmwareMutation,
  useRequestFirmwareUploadMutation,
} from './__generated__/CreateAndroidFirmwareReleaseModal.graphql';

interface Props {
  platform: PlatformFirmwareReleaseFragment;
  isOpen: React.ComponentProps<typeof Modal>['isOpen'];
  onClose: React.ComponentProps<typeof Modal>['onClose'];
}

enum FormStep {
  SelectFile = 0,
  AddMeta = 1,
  Release = 2,
}

function CreateAndroidFirmwareReleaseModal({ platform, isOpen, onClose }: Props) {
  const formSchema = getPlatformFormSchema(platform.type);
  type FormValues = z.TypeOf<typeof formSchema>;

  const [step, setStep] = useState(FormStep.SelectFile);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
    setError,
    reset,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const resetForm = useCallback(() => {
    reset();
    setStep(FormStep.SelectFile);
  }, [reset]);

  const dropzone = useDropzone({
    accept: getAcceptedFileTypesForPlatform(platform.type),
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) return;

      setValue('file', file);
      setStep(FormStep.AddMeta);
    },
  });

  const [requestUpload] = useRequestFirmwareUploadMutation();
  const [releaseFirmware] = useReleaseFirmwareMutation();

  let upload: (signedUrl: string, file: File, md5Checksum: string) => Promise<void>,
    isUploading: boolean,
    uploadProgress: number,
    uploadError,
    cancelUpload: () => void;
  if (process.env.REACT_APP_MOCK_S3_UPLOAD === 'true') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ({ upload, isUploading, uploadProgress, uploadError, cancelUpload } = useMockS3Upload());
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ({ upload, isUploading, uploadProgress, uploadError, cancelUpload } = useS3Upload());
  }

  const toast = useToast({ position: 'bottom-right' });

  const performSubmit = useCallback(
    async (values: FormValues) => {
      if (step !== FormStep.AddMeta) {
        throw new Error('Unexpected: Form is not in the right state to submit');
      }

      try {
        const checksum = encodeMD5Checksum(values.md5Hash);
        const urlData = await requestUpload({
          variables: {
            input: { platformId: platform.id, checksum, firmwareVersion: values.version },
          },
        });

        if (!urlData.data) {
          throw new Error('Unexpected: No data returned.');
        }

        const { uploadUrl, firmwareUrl } = urlData.data.firmwareUploadRequest;

        setStep(FormStep.Release);
        await upload(uploadUrl, values.file, checksum);

        if (process.env.REACT_APP_MOCK_S3_UPLOAD !== 'true') {
          await releaseFirmware({
            variables: {
              input: {
                platformId: platform.id,
                firmwareUrl,
                firmwareVersion: values.version,
                releaseNotes: values.releaseNotes,
              },
            },
          });
        }

        toast({
          title: 'New firmware released',
          description: `A new firmware release (${values.version}) was released for ${platform.name}.`,
          status: 'success',
        });

        resetForm();
        onClose();
      } catch (err) {
        if (err instanceof MD5ChecksumEncodingError) {
          setStep(FormStep.AddMeta);
          setError('md5Hash', {
            type: 'api',
            message: 'MD5 Checksum is not valid',
          });
          return;
        }

        switch (getErrorMessage(err)) {
          case 'invalid_firmware_version':
            setStep(FormStep.AddMeta);
            setError('version', {
              type: 'api',
              message: 'Version format is not valid',
            });
            break;
          case 'firmware_version_already_exists':
            setStep(FormStep.AddMeta);
            setError('version', {
              type: 'api',
              message: 'This version already exists',
            });
            break;
          default:
            console.log('Unhandled error: ', err);
        }
      }
    },
    [step, platform, requestUpload, releaseFirmware, upload, toast, resetForm, onClose, setError],
  );

  const watchFile = watch('file');
  const isReleasing = useMemo(
    () => step === FormStep.Release || isUploading || isSubmitting,
    [step, isUploading, isSubmitting],
  );
  const canCloseModal = !isReleasing;
  const platformFullName = [platform.name, platform.version].filter(Boolean).join(' ');

  const handleClose = useCallback(() => {
    if (isReleasing) {
      const shouldCancel = window.confirm('Closing this dialog will stop the firmware release');
      if (shouldCancel) {
        cancelUpload();
        resetForm();
        onClose();
      }
    } else {
      resetForm();
      onClose();
    }
  }, [resetForm, onClose, cancelUpload, isReleasing]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      closeOnEsc={canCloseModal}
      closeOnOverlayClick={canCloseModal}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(performSubmit)}>
          <ModalHeader>Create Firmware Release For {platformFullName}</ModalHeader>
          <ModalBody>
            <Stepper activeStepIndex={step}>
              <Stepper.Step>Select file</Stepper.Step>
              <Stepper.Step>Add metadata</Stepper.Step>
              <Stepper.Step>Release</Stepper.Step>
            </Stepper>
            <Box marginTop="6">
              {step === FormStep.SelectFile && (
                <Dropzone
                  {...dropzone}
                  dragActiveLabel={<Text>Drop the firmware archive here</Text>}
                  dragInactiveLabel={<Text>Drop the firmware archive here or click to select</Text>}
                />
              )}
              {step === FormStep.AddMeta && (
                <Box>
                  <Box marginBottom="6">
                    <Text color="gray.500">
                      Before releasing this firmware please add the required metadata.
                    </Text>
                  </Box>
                  <VStack alignItems="stretch" spacing="3">
                    <Box>
                      <FormControl isInvalid={Boolean(errors.md5Hash)}>
                        <FormLabel>MD5 Checksum:</FormLabel>
                        <Input
                          tabIndex={1}
                          placeholder="eg. bc527343c7ffc103111f3a694b004e2f"
                          {...register('md5Hash')}
                        />
                        <FormErrorMessage>{errors.md5Hash?.message?.toString()}</FormErrorMessage>
                        <FormHelperText>
                          You should have received an MD5 Checksum together with the Firmware
                          archive.
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isInvalid={Boolean(errors.version)}>
                        <FormLabel>Version:</FormLabel>
                        <Input
                          tabIndex={2}
                          placeholder={getPlatformSpecificFirmwarePlaceholder(platform.type)}
                          {...register('version')}
                        />
                        <FormErrorMessage>{errors.version?.message?.toString()}</FormErrorMessage>
                        <FormHelperText>Specify the version label.</FormHelperText>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isInvalid={Boolean(errors.releaseNotes)}>
                        <FormLabel>Release notes:</FormLabel>
                        <Textarea
                          tabIndex={3}
                          rows={6}
                          placeholder="Describe major and minor changes"
                          {...register('releaseNotes')}
                        />
                        <FormErrorMessage>
                          {errors.releaseNotes?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box>
                      <Text fontSize="sm" color="gray.500">
                        The release will be made for:{' '}
                        <chakra.span fontWeight="medium">{watchFile.name}</chakra.span>
                      </Text>
                    </Box>
                  </VStack>
                </Box>
              )}
              {step === FormStep.Release && (
                <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                  <VStack spacing="2">
                    {uploadError ? (
                      <WarningIcon width="120px" height="120px" color="red.300" />
                    ) : (
                      <CircularProgress
                        value={uploadProgress}
                        size="120px"
                        color={isUploading ? 'blue.500' : 'green.500'}
                      >
                        <CircularProgressLabel>{uploadProgress}%</CircularProgressLabel>
                      </CircularProgress>
                    )}
                    <Box>
                      {uploadError ? (
                        <Text>An error occured trying to upload the archive</Text>
                      ) : (
                        <>
                          {isUploading ? (
                            <Text fontSize="sm">
                              Archive being uploaded, this could take a few minutes...
                            </Text>
                          ) : (
                            <Text fontSize="sm">
                              Archive was uploaded successfully, creating a new release...
                            </Text>
                          )}
                        </>
                      )}
                    </Box>
                  </VStack>
                </Box>
              )}
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            {step === FormStep.AddMeta && (
              <Button
                variant="ghost"
                onClick={() => {
                  setStep(FormStep.SelectFile);
                }}
              >
                Select a new file
              </Button>
            )}
            {step === FormStep.Release && uploadError && (
              <Button
                variant="ghost"
                onClick={() => {
                  setStep(FormStep.SelectFile);
                }}
              >
                Try Again
              </Button>
            )}
            <Flex flex="1" justifyContent="flex-end">
              {step === FormStep.SelectFile && (
                <Button variant="ghost" onClick={handleClose}>
                  Close
                </Button>
              )}
              {step === FormStep.AddMeta && (
                <Button
                  type="submit"
                  variant="solid"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Release
                </Button>
              )}
              {step === FormStep.Release && (
                <Button variant="ghost" onClick={handleClose}>
                  Close
                </Button>
              )}
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

CreateAndroidFirmwareReleaseModal.graphql = {
  fragments: {
    PlatformFirmwareRelease: gql`
      fragment PlatformFirmwareRelease on Platform {
        id
        name
        version
        type
      }
    `,
  },
  mutations: {
    RequestFirmwareUpload: gql`
      mutation RequestFirmwareUpload($input: FirmwareUploadRequestInput!) {
        firmwareUploadRequest(input: $input) {
          uploadUrl
          firmwareUrl
        }
      }
    `,
    ReleaseFirmware: gql`
      mutation ReleaseFirmware($input: FirmwareReleaseInput!) {
        firmwareRelease(input: $input) {
          platform {
            id
            type
            latestFirmwareRelease {
              android {
                version
              }
            }
          }
        }
      }
    `,
  },
};

export default CreateAndroidFirmwareReleaseModal;
