import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { getErrorMessage } from '~graphql/error';
import {
  EditOrganizationModal_OrganizationFragment,
  UpdateOrganizationMutation,
  useUpdateOrganizationMutation,
} from './__generated__/EditOrganizationModal.graphql';
import { handleSchema, nameSchema } from './CreateOrganizationModal';

type Props = {
  organization: EditOrganizationModal_OrganizationFragment;
  isOpen: React.ComponentProps<typeof Modal>['isOpen'];
  onClose: React.ComponentProps<typeof Modal>['onClose'];
  onSuccess: (data: UpdateOrganizationMutation['organizationUpdate']) => void;
};

const formSchema = z.object({
  name: nameSchema,
  handle: handleSchema,
});

type FormValues = z.TypeOf<typeof formSchema>;

export function EditOrganizationModal({ organization, isOpen, onClose, onSuccess }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <EditOrganizationModalContent
          organization={organization}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </ModalContent>
    </Modal>
  );
}

function EditOrganizationModalContent({
  organization,
  onClose,
  onSuccess,
}: {
  organization: EditOrganizationModal_OrganizationFragment;
  onClose: Props['onClose'];
  onSuccess: Props['onSuccess'];
}) {
  const [updateOrganization] = useUpdateOrganizationMutation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      name: organization.name,
      handle: organization.handle,
    },
    resolver: zodResolver(formSchema),
  });

  const toast = useToast({ position: 'bottom-right' });

  const onSubmit = useCallback(
    async ({ name, handle }: FormValues) => {
      try {
        const { data } = await updateOrganization({
          variables: {
            input: {
              organizationId: organization.id,
              name,
              handle,
            },
          },
        });

        if (!data || !data.organizationUpdate) {
          throw new Error('Update Organization failed');
        }

        onSuccess(data.organizationUpdate);
      } catch (err) {
        switch (getErrorMessage(err)) {
          case 'ORGANIZATION_NAME_DUPLICATE': {
            setError('name', {
              type: 'api',
              message: 'Organization with the same name already exists',
            });
            break;
          }
          case 'ORGANIZATION_HANDLE_DUPLICATE': {
            setError('handle', {
              type: 'api',
              message: 'Organization with the same handle already exists',
            });
            break;
          }
          default: {
            toast({
              title: 'Could not edit organization',
              description: 'Unknown internal server error',
              status: 'error',
            });
            console.log('Unhandled error: ', err);
            break;
          }
        }
      }
    },
    [onSuccess, organization.id, setError, toast, updateOrganization],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>Edit organization</ModalHeader>
      <ModalBody>
        <VStack w="full" align="left">
          <Box>
            <FormControl isInvalid={Boolean(errors.name)}>
              <FormLabel>Name:</FormLabel>
              <Input tabIndex={1} placeholder="Organization name" {...register('name')} />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box>
            <FormControl isInvalid={Boolean(errors.handle)}>
              <FormLabel>Handle:</FormLabel>
              <Input tabIndex={2} placeholder="Organization handle" {...register('handle')} />
              <FormErrorMessage>{errors.handle?.message}</FormErrorMessage>
              <FormHelperText>Handle must be URL friendly</FormHelperText>
            </FormControl>
          </Box>
        </VStack>
        <ModalFooter>
          <Box>
            <Button variant="ghost" isDisabled={isSubmitting} onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              type="submit"
              variant="solid"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Edit
            </Button>
          </Box>
        </ModalFooter>
      </ModalBody>
    </form>
  );
}

EditOrganizationModal.graphql = {
  fragments: gql`
    fragment EditOrganizationModal_organization on Organization {
      id
      name
      handle
    }
  `,
  mutations: {
    UpdateOrganization: gql`
      mutation updateOrganization($input: OrganizationUpdateInput!) {
        organizationUpdate(input: $input) {
          id
          name
          handle
        }
      }
    `,
  },
};
