/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import { OrganizationRowFragmentDoc } from '../../components/organizations/__generated__/OrganizationsTable.graphql';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllOrganizationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllOrganizationsQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'Organization', id: string, name: string, handle: string, createdAt: any, displayCount: number, owner?: { __typename?: 'OwnerUser', id: string, email: string, lastLogin?: any | null } | null, proStore?: { __typename?: 'ProStoreConnection', id: string, createdAt: any, developerAccess: boolean } | null }> };


export const GetAllOrganizationsDocument = gql`
    query GetAllOrganizations {
  organizations {
    id
    ...OrganizationRow
  }
}
    ${OrganizationRowFragmentDoc}`;

/**
 * __useGetAllOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetAllOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>(GetAllOrganizationsDocument, options);
      }
export function useGetAllOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>(GetAllOrganizationsDocument, options);
        }
export type GetAllOrganizationsQueryHookResult = ReturnType<typeof useGetAllOrganizationsQuery>;
export type GetAllOrganizationsLazyQueryHookResult = ReturnType<typeof useGetAllOrganizationsLazyQuery>;
export type GetAllOrganizationsQueryResult = ApolloReactCommon.QueryResult<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>;