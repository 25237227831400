import { Accept } from 'react-dropzone';
import * as z from 'zod';
import { PlatformType } from '~graphql/__generated__/types';

const platformNotSupportedError = new Error('Platform not supported');

export function getPlatformFirmwareRegExp(type: PlatformType): RegExp {
  switch (type) {
    case PlatformType.Signage:
      return RegExp(/FB[0-9]{2}.[0-9]{2}/);
    case PlatformType.ProTv:
      return RegExp(/.*_R\.[0-9]{3}\.[0-9]{3}\.[0-9]{3}\.[0-9]{3}/);
    default:
      throw platformNotSupportedError;
  }
}

export function getAcceptedFileTypesForPlatform(type: PlatformType): Accept {
  switch (type) {
    case PlatformType.Signage:
      return {
        'application/arj': [],
        'application/octet-stream': [],
        'application/x-arj': [],
        'application/x-compress': [],
        'application/x-compressed': [],
        'application/x-gzip': [],
        'application/x-stuffit': [],
        'application/x-tar': [],
        'application/x-winzip': [],
        'application/x-zip': [],
        'application/x-zip-compressed': [],
        'application/zip': [],
        'multipart/x-zip': [],
        'zz-application/zz-winassoc-arj': [],
      };
    case PlatformType.ProTv:
      return {
        'application/java-archive': ['.upg'],
        'application/jar-archive': ['.upg'],
        'application/x-java-archive': ['.upg'],
      };
    default:
      throw platformNotSupportedError;
  }
}

export function getPlatformSpecificFirmwarePlaceholder(type: PlatformType): string {
  switch (type) {
    case PlatformType.Signage:
      return 'eg. FB03.02';
    case PlatformType.ProTv:
      return 'eg. TPM181HE_R.205.000.040.002';
    default:
      throw platformNotSupportedError;
  }
}

export function getPlatformFormSchema(type: PlatformType): z.ZodType {
  const formSchema = z.object({
    file: z.instanceof(File),
    md5Hash: z
      .string()
      .refine((val) => val?.length >= 10, 'MD5 hash should be at least 10 characters'),
    version: z
      .string()
      .regex(getPlatformFirmwareRegExp(type), 'Version format is not valid')
      .nonempty(),
    releaseNotes: z.string().optional(),
  });

  return formSchema;
}
